export const appointmentStatusEnums = Object.freeze({
  COMPLETED: 'completed',
  PENDING: 'pending',
  ACCEPTED: 'accepted',
  NO_SHOW: 'noShow',
  CANCELLED: 'cancelled',
  DECLINED: 'declined',
  // Note: the status inProgress should not be displayed in the FO
  IN_PROGRESS: 'inProgress',
})
